import { Alert, AlertTitle, Box, Button } from "@mui/material";
import { Iconify, SortBy } from "@the-source/front-core/components";
import type { NextPage } from "next";
import { useContext, useMemo, useState } from "react";
import Masonry from "../src/components/Masonry";
import Page from "../src/components/Page";
import MasonicAssetCard, { MasonicAssetCardProps } from "../src/components/assets/MasonicAssetCard";
import HeaderWithFilters from "../src/components/headers/HeaderWithFilters";
import { AuthContext, AuthContextType } from "../src/context/authContext";
import { FiltersContext, FiltersType } from "../src/context/filtersContext";
import useInspiredFilters from "../src/filters/inspired/useInspiredFilters";
import useInspiredVariables from "../src/filters/inspired/useInspiredVariables";
import { GetInspiredDocument, GetInspiredQuery, GetInspiredQueryVariables } from "../src/graphql/graphql";
import useMasonryQuery from "../src/hooks/useMasonryQuery";
import localization from "../src/localization";
import { getInspiredAssetsPerMasonryPage } from "../src/preset";
import getFallbackUrl from "../src/utils/getFallbackUrl";

type SortValue = "adStartedAt" | "creativeScore";

type SortOption = { value: SortValue; label: string };

const sortValues: SortOption[] = [
  { value: "adStartedAt", label: "Most recent" },
  { value: "creativeScore", label: "Best score" },
];

const Inspired: NextPage = () => {
  const page = "inspired";
  const { getFiltersConfig, getActiveFilters } = useContext(FiltersContext) as FiltersType;
  const { getUserIndustries } = useContext(AuthContext) as AuthContextType;
  const filtersConfig = getFiltersConfig(page);
  const activeFilters = getActiveFilters(page);
  const auth = useContext(AuthContext) as AuthContextType;

  const [showBrandAsset, setShowBrandAsset] = useState(true);
  const [sortBy, setSortBy] = useState<SortValue>("adStartedAt");

  useInspiredFilters();
  const { variables } = useInspiredVariables();
  const noIndustryIsSelected = !!variables && variables.industryIds.length < 1;
  const isSelectedIndustrySingleBranded = useMemo(
    () => !!variables && getUserIndustries().find(({ id }) => variables.industryIds[0] == id)?.singledBranded,
    [variables?.industryIds?.[0]]
  );

  const handleSortByChange = (value: SortValue): void => {
    setSortBy(value);
  };

  const toggleShowBrandAssets = (): void => {
    setShowBrandAsset(!showBrandAsset);
  };

  const getMasonryQueryOptions = (excludingBrand: boolean = false) => ({
    itemsPerPage: getInspiredAssetsPerMasonryPage,
    pagesPerBatch: 10,
    queryOptions: {
      query: GetInspiredDocument,
      variables: {
        ...variables,
        orderBy: [sortBy],
        orderDesc: [true],
        ...(excludingBrand && { excludedBrandIds: auth.getUser().brands.map((brand) => brand.id) }),
      },
      skip: !variables || noIndustryIsSelected,
    },
    selectData: (data: GetInspiredQuery) => data.assets,
  });

  const assetsQuery = useMasonryQuery<GetInspiredQuery, GetInspiredQueryVariables, MasonicAssetCardProps>(
    getMasonryQueryOptions()
  );

  const assetsExcludingBrandsQuery = useMasonryQuery<
    GetInspiredQuery,
    GetInspiredQueryVariables,
    MasonicAssetCardProps
  >(getMasonryQueryOptions(true));

  const {
    items: _assets,
    loadPage: loadNextAssets,
    isLoading,
    hasError,
  } = showBrandAsset ? assetsQuery : assetsExcludingBrandsQuery;

  const assets = useMemo(
    () =>
      _assets.map((asset) => {
        const [fileUrl] = getFallbackUrl([asset.fileUrl]);
        return {
          ...asset,
          fileUrl,
        };
      }),
    [_assets]
  );
  return (
    <Page
      title={localization.get_inspired.name}
      sx={{ pt: 0 }}
      header={{
        content: (
          <HeaderWithFilters
            title={localization.get_inspired.name}
            page={page}
            config={filtersConfig}
            activeFilters={activeFilters}
          />
        ),
        sticky: true,
      }}
    >
      <Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mb: 1.5 }}>
          <SortBy sortBy={sortBy} sortByOptions={sortValues} setSortBy={handleSortByChange} />
          {!isSelectedIndustrySingleBranded && (
            <Button
              variant={"text"}
              size={"small"}
              color={"inherit"}
              startIcon={
                showBrandAsset ? <Iconify icon={"eva:eye-off-outline"} /> : <Iconify icon={"eva:eye-outline"} />
              }
              onClick={toggleShowBrandAssets}
            >
              {showBrandAsset ? "Hide" : "Show"} my assets
            </Button>
          )}
        </Box>
        {noIndustryIsSelected ? (
          <Alert severity="info" sx={{ width: "fit-content" }}>
            <AlertTitle>No industry selected</AlertTitle>
            You must select at least one industry
          </Alert>
        ) : (
          <Masonry
            isLoading={isLoading}
            itemsPerPage={getInspiredAssetsPerMasonryPage}
            onNextPage={loadNextAssets}
            estimatedHeight={400}
            columnSize={300}
            dataType={"assets"}
            error={hasError}
          >
            {assets.map((asset) => (
              <MasonicAssetCard data={asset} key={asset.key} data-grid-groupkey={asset.groupKey} />
            ))}
          </Masonry>
        )}
      </Box>
    </Page>
  );
};

export default Inspired;
