import getPlatformIcon from "../../utils/getPlatformIcon";
import { AssetCard, AssetType, PercentageCircle } from "@the-source/front-core/components";
import { PlatformFacebook } from "@the-source/front-core/assets";
import { Box, Typography, useTheme } from "@mui/material";
import { thresholds } from "../../preset";
import { palette } from "@the-source/front-core/theme";
import React from "react";
import { Brand, Platform } from "../../graphql/graphql";
import LabelNew from "../LabelNew";
import EmptyPercentageCircle from "../chart/EmptyPercentageCircle";
import localization from "../../localization";
import { ReactSVG } from "react-svg";

export type MasonicAssetCardProps = {
  id: string;
  creativeScore?: number | null;
  platform: Platform;
  fileUrl: string;
  assetType: string;
  analysisDate?: string;
  brand:
    | {
        name: string;
      }
    | { __typename: "NotFoundError" };
  objectiveFamily: {
    objectiveFamily: string;
  };
};

const MasonicAssetCard = ({ data, ...other }: any): JSX.Element => {
  const theme = useTheme();
  const {
    id,
    creativeScore,
    platform,
    fileUrl,
    assetType,
    analysisDate,
    brand,
    objectiveFamily,
  }: MasonicAssetCardProps = data;
  const platformIcon = getPlatformIcon(platform);

  return (
    <AssetCard
      key={`${id}`}
      assetType={assetType == "IMAGE" ? AssetType.Image : AssetType.Video}
      assetUrl={fileUrl}
      topLeftChild={analysisDate && <LabelNew date={analysisDate} sx={{ marginLeft: 2, marginTop: 2 }} />}
      {...other}
    >
      <Box>
        <Box sx={{ display: "block", height: "50px" }}>
          {creativeScore ? (
            <PercentageCircle
              percentage={Math.round(creativeScore)}
              thresholds={thresholds.map((item) => ({
                threshold: item.threshold,
                color: theme.palette[item.color].main,
              }))}
              size={45}
            />
          ) : (
            <EmptyPercentageCircle size={45} />
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box flexDirection="column">
            <Typography variant="subtitle2" sx={{ py: 0.2, color: palette.light.common.black }}>
              {brand ? (brand as Brand).name : "NO BRAND FOUND"}
            </Typography>
            <Typography variant="body2" sx={{ py: 0.2, color: palette.light.text.secondary }}>
              {localization.objective[objectiveFamily.objectiveFamily]}
            </Typography>
          </Box>
          <Box>
            <ReactSVG
              src={platformIcon as typeof PlatformFacebook}
              style={{ height: "40px", width: "40px" }}
            />
          </Box>
        </Box>
      </Box>
    </AssetCard>
  );
};

export default MasonicAssetCard;
