import { filteredPage } from "../context/filtersContext";
import { AssetThemesDocument, AssetThemesQuery, AssetThemesQueryVariables } from "../graphql/graphql";
import createChoicesFieldParameter from "./createChoicesFieldParameter";
import useQueriedFilter from "./useQueriedFilter";

const useAssetThemesFilter = (page: filteredPage, required: boolean, variables?: AssetThemesQueryVariables) => {
  const { error } = useQueriedFilter<AssetThemesQuery, AssetThemesQueryVariables>(
    page,
    createChoicesFieldParameter({
      name: "theme",
      title: "Theme",
      defaultValues: [],
      required,
      choices: [],
    }),
    AssetThemesDocument,
    ({ assetThemes }) => assetThemes.map((theme) => ({ display: theme.name, value: theme.id })),
    "themes",
    variables
  );

  return { error };
};

export default useAssetThemesFilter;
