import { createBooleanCriteriaListParameter } from "@the-source/front-core/components/filters";
import { filteredPage } from "../../context/filtersContext";
import {
  FeatureCategoriesDocument,
  FeatureCategoriesQuery,
  FeatureCategoriesQueryVariables,
} from "../../graphql/graphql";
import useQueriedFilter from "../useQueriedFilter";

const useFeaturePresenceFilter = (page: filteredPage, variables: FeatureCategoriesQueryVariables) => {
  const { error } = useQueriedFilter<FeatureCategoriesQuery, FeatureCategoriesQueryVariables>(
    page,
    createBooleanCriteriaListParameter({
      name: "featurePresence",
      title: "Features",
      criteriasName: "features",
      positiveTitle: "Present in your assets",
      negativeTitle: "Absent in your assets",
      positiveCriteriaSuffix: "present",
      negativeCriteriaSuffix: "absent",
      defaultValues: [],
      choices: [],
      required: false,
    }),
    FeatureCategoriesDocument,
    ({ featureCategories }) =>
      featureCategories.map(({ categoryFrontName }) => ({
        display: categoryFrontName,
        value: categoryFrontName,
      })),
    "features",
    variables
  );

  return { error };
};

export default useFeaturePresenceFilter;
