import { useContext, useMemo } from "react";
import { AuthContext, AuthContextType } from "../../context/authContext";
import { FiltersContext, FiltersType } from "../../context/filtersContext";
import trimHourFromDate from "../../utils/trimHourFromDate";

const useInspiredVariables = () => {
  const page = "inspired";
  const { getActiveFilters, isInitialized, hasRequiredValues } = useContext(FiltersContext) as FiltersType;
  const { getUserIndustries } = useContext(AuthContext) as AuthContextType;
  const filters = getActiveFilters(page);

  const industryIds = getUserIndustries().length > 1 ? filters.industry : getUserIndustries().map(({ id }) => id);
  const variables = useMemo(() => {
    return isInitialized(page) && hasRequiredValues(page) && industryIds
      ? {
          industryIds,
          objectiveFamilyIds: filters.objective,
          brandIds: filters.brand,
          platforms: filters.platform,
          ...(filters.format &&
            filters.format.length == 1 && {
              format: filters.format[0],
            }),
          ...(filters.period &&
            filters.period[1] && {
              before: trimHourFromDate(filters.period[1]).toISOString(),
            }),
          ...(filters.period &&
            filters.period[0] && {
              after: trimHourFromDate(filters.period[0]).toISOString(),
            }),
          themeIds: filters.theme,
          ageRanges: filters.ageRange,
          genders: filters.gender,
          presentFeatures: filters.featurePresence.filter(({ positive }) => positive).map(({ value }) => value),
          absentFeatures: filters.featurePresence.filter(({ positive }) => !positive).map(({ value }) => value),
        }
      : undefined;
  }, [filters, isInitialized(page), hasRequiredValues(page), industryIds]);
  return { variables };
};
export default useInspiredVariables;
