import useAssetsBrandsFilter from "../useAssetsBrandsFilter";
import useIndustryFilter from "../useIndustryFilter";
import useInspiredFormatFilter from "./useInspiredFormatFilter";
import useInspiredPeriodFilter from "./useInspiredPeriodFilter";
import useInspiredVariables from "./useInspiredVariables";
import useObjectivesFilter from "../useObjectivesFilter";
import usePlatformFilter from "../usePlatformFilter";
import useThemesFilter from "../useAssetThemesFilter";
import useAgeRangesFilter from "./useAgeRangesFilter";
import useGenderFilters from "./useGenderFilters";
import useFeaturePresenceFilter from "./useFeaturePresenceFilter";

const useInspiredFilters = () => {
  const page = "inspired";
  const { variables } = useInspiredVariables();
  useIndustryFilter(page, false);
  useObjectivesFilter(page, false, variables);
  usePlatformFilter(page, false);
  useInspiredPeriodFilter(page);
  useInspiredFormatFilter(page);
  useAssetsBrandsFilter(page, variables);
  useThemesFilter(page, false, variables);
  useAgeRangesFilter(page, false, variables);
  useGenderFilters(page, false, variables);
  useFeaturePresenceFilter(page, variables);
};

export default useInspiredFilters;
