import { Alert } from "@mui/material";
import { createCheckboxListParameter } from "@the-source/front-core/components";
import { ExistingBrand } from "../@types/existingBrand";
import { filteredPage } from "../context/filtersContext";
import { BrandDocument, BrandQuery, BrandQueryVariables } from "../graphql/graphql";
import useQueriedFilter from "./useQueriedFilter";

const useAssetsBrandsFilter = (page: filteredPage, variables?: BrandQueryVariables) => {
  const brandsWithAssets = ({ brands }: BrandQuery) => {
    return brands
      .filter((brand) => brand.__typename == "Brand" && brand.assetsCount > 0)
      .sort((a: ExistingBrand, b: ExistingBrand) => a.name.localeCompare(b.name))
      .map((brand: ExistingBrand) => ({
        display: brand.name,
        value: brand.id,
      }));
  };

  const noIndustryIsSelected = variables && variables.industryIds.length == 0;

  const fieldParameter = createCheckboxListParameter({
    name: "brand",
    title: "Brand",
    required: false,
    defaultValues: [],
    choices: [],
  });

  const noIndustriesFieldParameter = {
    ...fieldParameter,
    placeholder: <Alert severity="warning">You must select an industry first</Alert>,
    choices: [],
  };

  const { error } = useQueriedFilter<BrandQuery, BrandQueryVariables>(
    page,
    noIndustryIsSelected ? noIndustriesFieldParameter : fieldParameter,
    BrandDocument,
    brandsWithAssets,
    "brands",
    variables
  );

  return { error };
};

export default useAssetsBrandsFilter;
