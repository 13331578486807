import { filteredPage } from "../../context/filtersContext";
import { GendersDocument, GendersQuery, GendersQueryVariables } from "../../graphql/graphql";
import localization from "../../localization";
import { gendersOrder } from "../../preset";
import sortByCustomOrder from "../../utils/sortByCustomOrder";
import createChoicesFieldParameter from "../createChoicesFieldParameter";
import useQueriedFilter from "../useQueriedFilter";

const useGenderFilters = (page: filteredPage, required: boolean, variables?: GendersQueryVariables) => {
  const { error } = useQueriedFilter<GendersQuery, GendersQueryVariables>(
    page,
    createChoicesFieldParameter({
      name: "gender",
      title: "Gender",
      defaultValues: [],
      choices: [],
      required,
    }),
    GendersDocument,
    ({ genders }) =>
      sortByCustomOrder([...genders], gendersOrder, ({ gender }) => gender).map(({ gender }) => ({
        value: gender,
        display: localization.genders[gender],
      })),
    "genders",
    variables
  );

  return { error };
};

export default useGenderFilters;
