import { useTheme } from "@mui/material";
import { PercentageCircle } from "@the-source/front-core/components";

type EmptyPercentageCircleProps = {
  thickness?: number;
  size?: number;
};

const EmptyPercentageCircle = ({ thickness, size }: EmptyPercentageCircleProps): JSX.Element => {
  const { palette } = useTheme();
  return (
    <PercentageCircle
      content="TBD"
      percentage={75}
      thresholds={[{ threshold: 0, color: palette.action.disabled }]}
      {...{ thickness, size }}
    />
  );
};

export default EmptyPercentageCircle;
