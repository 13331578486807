import { useContext, useEffect } from "react";
import { createCheckboxListParameter, renderCheckboxPreviewText } from "@the-source/front-core/components";
import { filteredPage, FiltersContext, FiltersType } from "../../context/filtersContext";
import { AssetType } from "../../graphql/graphql";

const useInspiredFormatFilter = (page: filteredPage) => {
  const { setFieldParameter } = useContext(FiltersContext) as FiltersType;

  useEffect(() => {
    setFieldParameter(
      page,
      createCheckboxListParameter({
        name: "format",
        title: "Format",
        defaultValues: [],
        onPreviewText: (value, options) =>
          value.length > 0 ? renderCheckboxPreviewText(value, options) : "All formats",
        choices: [
          {
            display: "Image",
            value: AssetType.Image,
          },
          {
            display: "Video",
            value: AssetType.Video,
          },
        ],
      })
    );
  }, [page]);
};

export default useInspiredFormatFilter;
