import { createPeriodParameter } from "@the-source/front-core/components";
import { renderPeriodPreviewText } from "@the-source/front-core/components/filters/fields/FiltersFieldPeriod";
import { useContext, useEffect } from "react";
import { filteredPage, FiltersContext, FiltersType } from "../../context/filtersContext";
import getDateXMonthsAgo from "../../utils/getDateXMonthsAgo";
import getNumberOfDaysBetweenTwoDates from "../../utils/getNumberOfDaysBetweenTwoDates";
import getTodayDate from "../../utils/getTodayDate";

const useInspiredPeriodFilter = (page: filteredPage) => {
  const { setFieldParameter } = useContext(FiltersContext) as FiltersType;

  useEffect(() => {
    setFieldParameter(
      page,
      createPeriodParameter({
        name: "period",
        title: "Period",
        defaultValues: [getDateXMonthsAgo(3, getTodayDate()), getTodayDate()],
        onPreviewText: (value) => (value.length > 0 ? renderPeriodPreviewText(value) : "All periods"),
        choices: [],
        onValidation: (value: Date[]) => {
          return getNumberOfDaysBetweenTwoDates(value[1], value[0]) < 15
            ? [null, "Select an end date at least 15 days after start date"]
            : [];
        },
      })
    );
  }, []);
};

export default useInspiredPeriodFilter;
