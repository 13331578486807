import { filteredPage } from "../../context/filtersContext";
import { AgeRangesDocument, AgeRangesQuery, AgeRangesQueryVariables } from "../../graphql/graphql";
import createChoicesFieldParameter from "../createChoicesFieldParameter";
import useQueriedFilter from "../useQueriedFilter";

const useAgeRangesFilter = (page: filteredPage, required: boolean, variables?: AgeRangesQueryVariables) => {
  const { error } = useQueriedFilter<AgeRangesQuery, AgeRangesQueryVariables>(
    page,
    createChoicesFieldParameter({
      name: "ageRange",
      title: "Age Range",
      defaultValues: [],
      choices: [],
      required,
    }),
    AgeRangesDocument,
    ({ ageRanges }) => ageRanges.map(({ ageRange, name }) => ({ value: ageRange, display: name })),
    "age ranges",
    variables
  );

  return { error };
};

export default useAgeRangesFilter;
